import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/common/layout/seo"
import { Flex } from "../components/global"
import PageLayout from "../components/common/layout/pageLayout";

const Usecases = () => {
  const data = useStaticQuery(graphql`
    query {
      school: file(sourceInstanceName: {eq: "usecase"}, name: {eq: "classroom"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      manufacturing: file(sourceInstanceName: {eq: "usecase"}, name: {eq: "manufacturing"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      medical: file(sourceInstanceName: {eq: "usecase"}, name: {eq: "medical"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      company: file(sourceInstanceName: {eq: "usecase"}, name: {eq: "company"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <PageLayout>
      <SEO title="UseCases" />
      <h1>Usecases</h1>
      <Flex>
        <StyledContainer>
          <Img fluid={data.school.childImageSharp.fluid} alt="school" style={{ position: "static" }}></Img>
          <CenteredText>
            <div>
              <h4>Schools, Colleges, Coaching Institutions</h4>
            </div>
          </CenteredText>
        </StyledContainer>
        <StyledContainer>
          <Img fluid={data.medical.childImageSharp.fluid} alt="Medical" style={{ position: "static" }}></Img>
          <CenteredText>
            <div>
              <h4>Medical</h4>
            </div>
          </CenteredText>
        </StyledContainer>
        <StyledContainer>
          <Img fluid={data.manufacturing.childImageSharp.fluid} alt="Manufacturing"></Img>
          <CenteredText>
            <div>
              <h4>Manufacturing</h4>
            </div>
          </CenteredText>
        </StyledContainer>

        <StyledContainer>
          <Img fluid={data.company.childImageSharp.fluid} alt="Companies"></Img>
          <CenteredText>
            <div>
              <h4>Companies</h4>
            </div>
          </CenteredText>
        </StyledContainer>
      </Flex>
      <p>Hedera Learning is not limited to above cases, but can be used anywhere learning is needed</p>
    </PageLayout>
  );
}

export default Usecases;

const StyledContainer = styled.div`
  position:relative;
  text-align: center;
  color: white;
`

const CenteredText = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00008b75;
`
/** <span>Photo by <a href="https://unsplash.com/@thisisengineering?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">ThisisEngineering RAEng</a> on <a href="https://unsplash.com/s/photos/manufacturing?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
<span>Photo by <a href="https://unsplash.com/@mclee?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">MChe Lee</a> on <a href="https://unsplash.com/s/photos/school?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
<span>Photo by <a href="https://unsplash.com/@nci?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">National Cancer Institute</a> on <a href="https://unsplash.com/s/photos/doctor-with-laptop?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
<span>Photo by <a href="https://unsplash.com/@sctgrhm?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Scott Graham</a> on <a href="https://unsplash.com/s/photos/companies-learning?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */
